@import "fonts";
.menu {
  &--desktop {
    margin-top: 4.7%
  }
  &--desktop-logo {
    width: 180px;
    padding-top: 2px;
  }

  &--desktop-wrapper {
    margin-top: 60px !important;
  }

  &--mobile-logo {
    height: auto;
    padding-top: 15px;

    @include mq($from: mobile) {
    width: 28%;
    padding-top: 8px;
    left: 35%;
    }

    @include mq($from: tablet) {
    width: 12%;
    padding-top: 15px;
    left: 40%;
    }
  }

  &--desktop li a {
    &:hover {
      color: $link-color;
    }
  }
}

.row--filters-bar {
  top: 75px;
  @include mq($from: desktop) {
    top: 175px;
  }
}
.sticky--header {
  height: 75px;
  @include mq($from: desktop) {
  height: 175px;
  }
}

.header-right {
  .menu--desktop-wrapper {
    margin-top: 0px !important;
  }
  .display-row {

    @include mq($from: desktop) {
      margin-top: 35px !important;
    }

  }
}

h1, h2, h3, .heading--large, .card__title {
  font-family: $primary-font !important;
  line-height: 1.15;
}

footer {
  .menu--desktop-wrapper {
    margin-top: 2rem !important;
  }
}

.content-group-sidepanel__button--is-active {
  color: white !important;
}

@media only screen and (max-width: 768px) and (min-width: 520px) {
  .menu--mobile-logo {
    padding-top: 15px !important;
    width: 15% !important;
    left: 42% !important;
  }
}

@media only screen and (max-width: 335px) and (min-width: 0px) {
  #mce-EMAIL {
    min-width: 261px !important;
  }

  .menu--mobile-logo {
    padding-top: 15px;
  }

}


@media only screen and (max-width: 335px) and (min-width: 0px) {
  .menu--mobile-logo {
    width: 32%;
    padding-top: 15px;
    left: 32%;
  }
}

@media only screen and (max-width: 1320px) and (min-width: 1024px) {
  .sticky--header {
    height: 115px;
  }

  .menu--desktop-wrapper {
    margin-top: 90px !important;
  }
  .header-right .display-row {
    margin-top: 10px !important;
  }

  .menu--desktop-wrapper li {
    margin-right: 20px;
    font-size: 1.05rem;
  }

  .menu--desktop-logo {
    width: auto !important;
  }
}
